import './DashboardProjects.css';
import './ManualTimeApproval.css';
import { get, post } from "../../api/apiCalls";
import React, { useState, useEffect, useRef} from 'react';
import DataTableBase from '../../ui/DataTableBase';
import moment from 'moment';
import { ReactSession } from 'react-client-session'
import Select from 'react-select';
import RejectReasonModal from '../dashboard/RejectReasonModal';
import LoadingSpinner from '../../elements/UiElements/LoadingSpinner';
import { Toast } from 'primereact/toast';

function DashboardManualTimeApproval(props) {
  const toast = useRef(null)
  const [openAddModal, setOpenAddModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState();
  const [manualTime, setManualTime] = useState([])
  const [users, setUsers] = useState([])
  const [projects, setProjects] = useState([])
  const [selectedUser, setSelectedUser] = useState()
  const [selectedProject, setSelectedProject] = useState()
  const [filtered, setFiltered] = useState([])
  let [loading, setLoading] = useState(true)
  let [employee] = useState();
  let [project] = useState()
  let requestStatus = ["Requested","Approved","Rejected"];
  let rowID, requestrowID, statusrowID;
  const user = ReactSession.get("User")

  const showInfo = (text) => {
    toast.current.show({severity:'info', summary: 'Info', detail:text, life: 3000});
  }

  useEffect(() => {
    setLoading(true)
    const fetchData = async () => {
        let list = [];
        let projectList = [];
        
        try {
            let users = await get("Users/GetAllUsers?disabled=false");
            console.log("USERS: ", users);

            users.forEach(e => {
                let model = {
                    value: e.id,
                    label: `${e.firstName} ${e.lastname}`
                };
                list.push(model);
            });
            setUsers(list);

            let projects = await get("Projects?disabled=false");
            projects.forEach(e => {
                let model = {
                    value: e.id,
                    label: e.Name
                };
                projectList.push(model);
            });
            setProjects(projectList);

            getManualTimes();
            setLoading(false)
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    fetchData();
  }, []);

  function openRejectReasonModal(row) {
    setSelectedRow(row)
    setOpenAddModal(true);
  }

  function cancelRejectReasonModal() {
    setOpenAddModal(false);
  }

  const getManualTimes = async () => {
    let manualTimes = []
    let keys = {
      status: 0
    }
    const mts = await post("Request/ManualTime/GetRequestsByStatus", keys)
    console.log("MTS: ", mts)
    for(let i = 0 ; i < mts.length ; i++ ) {
      manualTimes.push( {
        id : i,
        Date : moment(mts[i].date).format("YYYY-MM-DD"),
        Name: mts[i].name,
        Hours : mts[i].hours,
        Project: mts[i].projectName,
        reason : mts[i].reason,
        status : requestStatus[mts[i].status],
        userID : mts[i].userID,
        approvalDate: mts[i].approvalDate == null? '': moment(mts[i].approvalDate).format('YYYY-MM-DD'),
        type: 'Manual Time'
      });
      setManualTime([...manualTimes]);
    }
  }

  const columns = [
    {
      name: "Date",
      selector: (row) => row.Date,
      sortable: true,
      reorder: true,
    },
    {
      name: "User",
      selector: (row) => row.Name,
      sortable: true,
      reorder: true,
    },
    {
      name: "Project",
      selector: (row) => row.Project,
      sortable: true,
      reorder: true,
    },
    {
      name: "Hours",
      selector: (row) => row.Hours,
      sortable: true,
      reorder: true,
    },
    {
      name: "reason",
      selector: (row) => row.reason,
      sortable: true,
      reorder: true,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      button: true,
      wrap: true,
      minWidth: "200px",
      conditionalCellStyles: [
        {
          when: (row) => row.status == "Approved",
          classNames: ["approved"],
        },
        {
          when: (row) => row.status == "Rejected",
          classNames: ["rejected"],
        },
        {
          when: (row) => row.status == "Requested",
          classNames: ["requested"],
        },
      ],
      cell: (row) => (
        (requestrowID = "request-buttons-" + row.id.toString()),
        (statusrowID = "request-status-" + row.id.toString()),
        (rowID = row.status),
        (
          <div>
            <div
              className="manual-hours-buttons"
              id={requestrowID}
              style={{ display: rowID == "Requested" ? "block" : "none" }}
            >
              <button
                className="approve-button"
                onClick={() => ApproveRequest(row, requestrowID, statusrowID)}
                id={row.id}
              >
                Approve
              </button>
              <button
                className="reject-button"
                onClick={() => openRejectReasonModal(row)}
                id={row.id}
              >
                Reject
              </button>
            </div>
            <div
              id={statusrowID}
              value={row.status}
              style={{ display: rowID == "Requested" ? "none" : "block" }}
            >
              {row.status}
            </div>
          </div>
        )
      ),
      sortable: true,
      reorder: true,
    },
  ];

  const filteteringManualTimeList = () => {
    setLoading(true)
    let manualTimeList = []
    if(selectedProject && selectedUser){
      manualTimeList = manualTime.filter(mt => mt.Project == selectedProject && mt.Name == selectedUser)
    }else if(selectedProject && !selectedUser){
      manualTimeList = manualTime.filter(mt => mt.Project == selectedProject)
    }else if(!selectedProject && selectedUser){
      manualTimeList = manualTime.filter(mt => mt.Name == selectedUser)
    }
    setFiltered(manualTimeList)
    if(manualTimeList.length <=0){
      showInfo("There is no pending MT for the selected data.")
    }
    setLoading(false)
  }

  const ApproveRequest = (row, requestrowID, statusrowID) => {
    console.log(row, requestrowID, statusrowID);
    changeStatus(row,1);
    getManualTimes()

  };

  const RejectRequest = (row, requestrowID, statusrowID) => {
    console.log(row, requestrowID, statusrowID);
    changeStatus(row,2);
    getManualTimes()
  };

  let changeStatus= async(requestData,status) => {
    console.log("CANCELANDO")
    let formatedValue = requestData.Hours.toString()
    let keys = {
      date : moment(requestData.Date).format("YYYY-MM-DD"),
      status : status,
      managerID : user.id,
      userID : requestData.userID,
      project: requestData.Project,
      hours: formatedValue
    };
    console.log("KEYS: ", keys)
   let result = await post("Request/ManualTime/ChangeStatusByDate",keys);
    if(result !=undefined){
      showInfo(result)
    }
    getManualTimes()
  };
  
  function closeRejectReasonModal(response) {
    setOpenAddModal(false);
    showInfo(response)
    getManualTimes()
  }

  const handleSelectedUser = (employee) => {
    if(employee !=null){
      setSelectedUser(employee.label)
    }else{
      setSelectedUser('')
    }
  };

  const handleSelectedProject = (project) => {
    if(project !=null){
      setSelectedProject(project.label)
    }else{
      setSelectedProject('')
    }

  };

  return (
    <div className='dashboardProjectsMain ManualTimeApproval'>
      <Toast ref={toast} position="top-center"/>
      <div className='dashboardProjectsMain-header dashboardManualTimeApproval-header'>Manual Time Approval</div>
      <div className="filters">
        <Select
            className="basic-single"
            classNamePrefix="select"
            placeholder="Users..."
            name="color"
            value={employee}
            isClearable
            onChange={handleSelectedUser}
            options={users}
        />
        <Select
            className="basic-single"
            classNamePrefix="select"
            placeholder="Projects..."
            name="color"
            value={project}
            isClearable
            onChange={handleSelectedProject}
            options={projects}
        />
        <div><button className='filter-mt-button' onClick={filteteringManualTimeList}>Search</button></div>
      </div>
      <div>
        {loading && (
          <LoadingSpinner />
        )}
        {!loading && (
          <>
            {filtered.length > 0 ? (
              <DataTableBase columns={columns} data={filtered}  fileName={"Manual_Time_Requests"}/>
            ): (
              <DataTableBase columns={columns} data={manualTime}  fileName={"Manual_Time_Requests"}/>
            )}
          </>
        )}
      </div>
      {openAddModal && <RejectReasonModal onCancel={closeRejectReasonModal} onClose={cancelRejectReasonModal} selectedRow={selectedRow} managerID={user.id} userID={selectedRow.userID}/>}
    </div>
  );
}

export default DashboardManualTimeApproval;
