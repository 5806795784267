import "./Compliance.css"
import "./UserOvertime.css";
import { useParams } from "react-router-dom";
import ManagerSubHeaderLinks from "../../layout/header/ManagerSubHeaderLinks";
import { useEffect, useRef, useState } from "react";
import { fetchImageFromAPI, get, post } from "../../api/apiCalls";
import { TbListDetails } from "react-icons/tb";
import ComplianceModal from "../dashboard/ComplianceModal";
import DataTableBase from "../../ui/DataTableBase";
import UserShorProfile from "../../elements/summaryElements/UserShorProfile";
import LoadingSpinner from "../../elements/UiElements/LoadingSpinner";
import { baseURL } from "../../api/env";
import DefaultProfileImage from '../../../images/default.jpg'
import { Toast } from "primereact/toast";

function UserCompliances(props){
    const toast = useRef(null)
    const params = useParams()
    const [compliance, setCompliance] = useState('')
    const [compliances, setCompliances] = useState([])
    const [openModal, setOpenModal] = useState(false)
    let [euser,setEuser] = useState(0);
    let [loading, setLoading] = useState(true)

    const showInfo = (text) => {
        toast.current.show({severity:'success', summary: 'Success', detail:text, life: 3000});
    }

    const columns = [
        {
            name: "User",
            selector: (row) => row.user,
            sortable: true,
            reorder: true,
        },
        {
            name: "Compliance Type",
            selector: (row) => row.complianceType,
            sortable: true,
            reorder: true,
        },
        {
            name: "Project",
            selector: (row) => row.project,
            sortable: true,
            reorder: true,
        },
        {
            name: "Manager",
            selector: (row) => row.manager,
            sortable: true,
            reorder: true,
        },
        {
            name: "Create Date",
            selector: (row) => row.createDate,
            sortable: true,
            reorder: true,
        },
        {
            name: "Status",
            selector: (row) => row.isOpen === true? 'Open': 'Closed',
            sortable: true,
            reorder: true,
        },
        {
            name: "Updated At",
            selector: (row) => row.updatedAt,
            sortable: true,
            reorder: true,
        },
        {
            name: "Updated By",
            selector: (row) => row.updatedBy,
            sortable: true,
            reorder: true,
        },
        {
            name: "Detail",
            selector: (row) => {
                return(
                    <button className="compliance-detail-btn" onClick={() => openComplianceDetails(row)}>
                        <TbListDetails />
                    </button>
                )
            },
            sortable: true,
            reorder: true,
        },
        
    ];


    async function getCompliances(){
        let response = await get(`Compliance?userID=${params.userId}`)
        setCompliances(response)
    }

    async function closeModal(){
        setOpenModal(false)
        await getCompliances()
    }

    async function closeModalByClick(response){
        setOpenModal(false)
        showInfo(response)
        await getCompliances()
    }

    function openComplianceDetails(e){ 
        setCompliance(e)
        setOpenModal(true)
    }

    
    const getUser = async () => {
        
        
        let keys = {
            id : params.userId
        };
        let u = await post("Users/GetUserById",keys);
        u.image = u.image === null ? DefaultProfileImage : await fetchImageFromAPI(`${baseURL}TrackingData/GetProfileImages?ImagePath=${u.image}`)
        setEuser(u);
        setLoading(false)
    }

    useEffect(() => {
        const fetchData = async() => {
            try {

              await getCompliances()
              await getUser()
              setLoading(false)
            } catch (error) {
              console.error("ERROR: ", error)
            }
          }
          
          fetchData()
    }, [])
    return(
        <div className="summary">
            <Toast ref={toast} position="top-center"/>
            {loading && <LoadingSpinner />}
            {!loading && (
                <div className="summaryMain">
                    <div className="my-dashboard-header">
                        <UserShorProfile
                            employeeName={euser.firstName + ' ' + euser.lastname}
                            employeePronoun={euser.pronoun}
                            employeeDesignation={euser.jobDescription}
                            employeeID={euser.id}
                            employeeUsername={euser.username}
                            employeeLocation={euser.city}
                            employeePicture={euser.image}
                            employeeDisabled={euser.disabled}
                            employeeContractEndDate={euser.contract_endDate}
                        />
                        <ManagerSubHeaderLinks id={params.userId}/> 
                    </div>
                    <div className="summaryDiary">
                    <h1>Compliance Items</h1>
                        <DataTableBase columns={columns} data={compliances}  fileName={"user_compliance"}/>
                    </div>
                </div>
            )}
            {openModal && <ComplianceModal compliance={compliance} onCloseModal={closeModal} onCloseModalByClick={closeModalByClick}/>}
        </div>
    )
}

export default UserCompliances;