import ManagerSidebar from "../components/layout/sidebar/ManagerSidebar";
import AdditionalPaymentsPage from "../components/manager/dashboard/AdditionalPaymentsPage";
function AdditionalPayments() {
  return (
    <div>
      <AdditionalPaymentsPage />
    </div>
  );
}

export default AdditionalPayments;
