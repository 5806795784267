import DataTableBase from "../../ui/DataTableBase";
import { get, post } from "../../api/apiCalls";
import { ReactSession } from 'react-client-session';
import { useEffect, useRef, useState } from "react";
import { MdAddCircleOutline } from "react-icons/md";
import { AutoComplete } from "primereact/autocomplete";
import { Toast } from "primereact/toast";

import './ProjectTeamModal.css'

export function ProjectTeamModal(props){
  const toast = useRef(null)
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null); 

  let user = ReactSession.get("User");

  const showInfo = (text) => {
    toast.current.show({severity:'success', summary: 'Success', detail:text, life: 3000});
}

  const addMember = async() => {
    console.log("SELECTED USER: ", selectedUser)
    if(selectedUser!='')
    {
      showInfo(selectedUser.name + " added to " + props.teamMembers.project);
      let keys = { agentUsername:selectedUser.username , managerUsername: user.username , projectID : props.teamMembers.id };
      await post("Projects/AssignAgent",keys);
      props.updateTeamMembers(props.teamMembers)
    }
  }

  const search = (event) => {
    const query = event.query.toLowerCase();
    
    const filtered = query 
        ? users.filter(user => user.name.toLowerCase().includes(query)) 
        : users;
    
    setFilteredUsers(filtered);
  };

  const removeMember = async(data) => {
    
    let keys = { agentUsername:data.username , managerUsername: user.username , projectID : props.teamMembers.id };
    await post("Projects/RemoveAgent",keys);
    showInfo(`Agent was removed from project`)
    props.updateTeamMembers(props.teamMembers)
  }

  const columns = [
    {
      id: 'name',
      name: 'Name',
      selector: (row) => row.agentname,
    },
    {
      id: 'removeAgent',
      name: 'Remove Agent',
      selector: (row) => row.removeAgent,
      cell: (row) => {
        return (
          <button className='team-members-remove-agent-btn' onClick={() => removeMember(row)}>
            Delete Member
          </button>
        );
      },
    },
  ];

  function closeProjectTeamMembersModal(){
    props.onClose()
  }
  
  async function loadUsers(){
    let usersList = []
    let response = await get('Users/GetAllUsers?disabled=false')
    response.forEach(element => {
      let user = {
        name: element.firstName + ' ' + element.lastname,
        username: element.username
      }
      usersList.push(user)
    });
    setUsers(usersList)
  }
  
  useEffect(() => {
    const fetchData = async () => {
      try {
          await loadUsers();
      } catch (error) {
          console.error("Error fetching users:", error);
      }
    };
    fetchData();
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
      props.onClose(); 
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
        window.removeEventListener('keydown', handleKeyDown);
    };
  }, [props.onClose])

  function closeModal(){
    props.onClose()
  }

  return(
    <div className='team-members-modal-page' onClick={(e) => {
        if (e.target === e.currentTarget) {
          closeModal();
        }
      }}
    >
      <Toast ref={toast} position="top-center"/>
      <div className='team-members-modal-box'>
        <div className='project-team-members-headers'>Project Team Members</div>
        <div id='section-project-members'>
          <div className='add-team-member-to-project'>
            <AutoComplete 
              value={selectedUser} 
              className="auto-complete-dropdown"
              suggestions={filteredUsers} 
              completeMethod={search} 
              field="name" 
              onChange={(e) => setSelectedUser(e.value)} 
              dropdown 
              virtualScrollerOptions={{ itemSize: 38 }} 
              placeholder="Search" 
            />
            <MdAddCircleOutline 
              size={'1.5em'}
              cursor={'pointer'}
              onClick={addMember}
            />
          </div>
          <DataTableBase data={props.teamMembers.agents} columns={columns} fileName={"Project Members"}/>
        </div>
        <div className="team-members-modal-btn">
          <button onClick={closeProjectTeamMembersModal} className="team-members-close-modal-btn">Close</button>
        </div>
      </div>
    </div>
  )
}