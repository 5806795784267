
import { useEffect, useMemo, useState } from 'react';
import { get } from '../../api/apiCalls.js';
import { InputText } from "primereact/inputtext";
import { InputNumber } from 'primereact/inputnumber';
import { FloatLabel } from "primereact/floatlabel";
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';

import './AddProjectModal.css'

function AddProjectsModal(props) {
  const [projectName,setProjectName] = useState('')
  const [contactName,setContactName] = useState('')
  const [hours,setHours] = useState(0)
  const [managers, setManagers] = useState([]);
  const [manager, setManager] = useState('');
  const [sharedService, setSharedService] = useState(false);
  function setShared() {
    setSharedService(!sharedService);
  }

  const getManagers = async() => {
    let response = await get("Users/GetManagers")
    console.log("MANAGERS RESPONSE: ", response)
    let managersList = []
    response.forEach(item => {
        let manager = {
          id: item.id,
          name: item.firstName + ' ' + item.lastName,
          email: item.username
        }
        managersList.push(manager)
    });
    setManagers(managersList)

  }

  const handleManagerChange = (event) => {
      setManager(event.target.value)
  }

  function handleCloseModal(){
    props.onClose()
  }
  function handleAddProject(event){
    event.preventDefault()
    console.log("CONTACT NAME: ", contactName)
    let project = {
      Name : projectName,
      ContactName : contactName,
      Hours : hours,
      sharedService : sharedService,
      managerEmail : manager.email,
      manager : manager.name,
      managerId : manager.id
    }
    console.log("PROJECT: ", project)
    props.addProject(project)
  }
  
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
      props.onClose(); 
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
        window.removeEventListener('keydown', handleKeyDown);
    };
  }, [props.onClose])
  
  useMemo(() => {
    getManagers();
  }, [])

  return (
    <div className='add-project-modal-container'>
      <div className='add-project-modal-box'>
        <h4 className='add-project-modal-title'>Project Details</h4>
        <form className='add-project-modal-form' onSubmit={handleAddProject} onReset={handleCloseModal}>
          <div className='add-project-modal-input-box'>
            <FloatLabel>
              <InputText id="project-name" className='add-project-modal-input' value={projectName} onChange={(e) => setProjectName(e.target.value)} autoFocus/>
              <label htmlFor="project-name">Project Name *</label>
            </FloatLabel>
          </div>

          <div className='add-project-modal-input-box'>
            <FloatLabel>
              <InputText id="contact-name" className='add-project-modal-input' value={contactName} onChange={(e) => setContactName(e.target.value)} />
              <label htmlFor="contact-name">Contact Name *</label>
            </FloatLabel>
          </div>

          <div className='add-project-modal-input-box'>
            <FloatLabel>
              <Dropdown inputId="manager" value={manager} onChange={(e) => handleManagerChange(e)} options={managers} optionLabel="name" className="add-project-modal-dropdown"/>
              <label htmlFor="manager">Select a Manager *</label>
            </FloatLabel>
          </div>

          <div className='add-project-modal-input-box add-project-modal-hour-and-shared'>
            <FloatLabel>
              <InputNumber min={0} id="hour-cap" value={hours} onValueChange={(e) => setHours(e.value)} className='add-project-modal-input-number'/>
              <label htmlFor="hour-cap">Hour Cap Per Agent *</label>
            </FloatLabel>
          </div>
          <div className='add-project-modal-shared-input'>
            <label htmlFor="shared-service" className="ml-2">Shared</label>
            <Checkbox inputId="shared-service" name="shared" value="shared" onChange={setShared} checked={sharedService} />
          </div>

          <div className='add-project-modal-input-box add-project-modal-btns'>
            <Button label="Cancel" severity="warning" raised type='reset'/>
            <Button label="Save" raised type='submit' severity='success' disabled={projectName === '' || contactName === '' || manager === undefined || hours === 0}/>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AddProjectsModal;
