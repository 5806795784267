import React, { useEffect, useRef, useState } from "react"
import "./Tracker.css"
import { ReactSession } from "react-client-session"
import { Box, Fade, FormControlLabel, FormGroup, Popper, Switch, Typography } from "@mui/material"
import { MdTimer } from "react-icons/md"
import { get, post, put } from "../../api/apiCalls"
import moment from "moment/moment"
import { Toast } from "primereact/toast"

ReactSession.setStoreType("sessionStorage");
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderRadius: '2px',
    boxShadow: 24,
    p: 4,
  };

export default function Tracker(){
    const toast = useRef(null)
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    let [projects, setProjects] = useState([])
    let [project, setProject] = useState(ReactSession.get("Tracker").project)
    let [lastUpdate, setLastUpdate] = useState(localStorage.getItem("LastUpdate"))
    const [weeklyTrackedTime, setWeeklyTrackedTime] = useState(ReactSession.get("Tracker").weeklyTime)
    const [dailyTracketTime, setDailyTrackedTime] = useState(ReactSession.get("Tracker").dailyTime)
    const [checked, setChecked] = useState(ReactSession.get("Tracker").online)
    let user = ReactSession.get("User")
    let trackerDetail = ReactSession.get("Tracker")
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
      setOpen((previousOpen) => !previousOpen);
    };

    const canBeOpen = open && Boolean(anchorEl);
    const id = canBeOpen ? 'transition-popper' : undefined;

    const getUserProjects = async() => {
        let response = await get(`Projects/GetProjectsForAgent?UserId=${user.id}`)
        setProjects(response)
    }

    const showInfo = (text) => {
        toast.current.show({severity:'info', summary: 'Info', detail:text, life: 3000});
      }

    // function to fetch the weekly tracked time
    const getWeeklyTrackedTime = async(userID, projectID) => {
        let keys = {
            userID,
            projectID: projectID
        }
        let response = await post("Projects/UserCurrentWeekReport", keys)
        trackerDetail.weeklyTime = response.weeklyTime
        trackerDetail.dailyTime = response.dayTime
        
        ReactSession.set("Tracker", trackerDetail)
        setWeeklyTrackedTime(response.weeklyTime)
        setDailyTrackedTime(response.dayTime)
    }

    const getWeeklyTrackedTimeUpdated = async(userID, projectID) => {
        let keys = {
            userID,
            projectID: projectID
        }
        let response = await post("Projects/UserCurrentWeekReport", keys)
        trackerDetail.dailyTime = response.dayTime
        
        ReactSession.set("Tracker", trackerDetail)
        setDailyTrackedTime(response.dayTime)
    }
    // function to controll the projects selections and call getWeeklyTrackedTime when project !=='default'
    const handleProjectSelection = async(e) => {
        if(e.target.value !='default'){
            let selectedProject = e.target.value
            trackerDetail.project = e.target.value
            ReactSession.set("Tracker", trackerDetail)
            await getWeeklyTrackedTime(user.id ,e.target.value)
            setProject(selectedProject)
            let keys = {
                UserID: user.id
            }
            let response = await post("DesktopApp/Online", keys)
            sessionStorage.setItem("session", JSON.stringify(response.id))
        }else{
            trackerDetail.weeklyTime = 0
            trackerDetail.dailyTime = 0
            trackerDetail.project= "default"
            ReactSession.set("Tracker", trackerDetail)
            setWeeklyTrackedTime(0)
            setDailyTrackedTime(0)
            setProject("default")
        }
    }

    
    // function to controll the toggle btn (on/off)
    const handleChange = async(event) => {
        trackerDetail.online = event.target.checked
        ReactSession.set("Tracker", trackerDetail)
        ReactSession.set("StartTime", moment().format('YYYY-MM-DD HH:mm:ss'))
        setChecked(event.target.checked)
        if(event.target.checked){
            let jobId = sessionStorage.getItem("jobID")
            if(jobId !=null){
                let key = {
                    jobId
                }
                await post('WebTracker/ResumeTracker', key)
            }else{
                let keys = {
                    status: event.target.checked,
                    username: user.username,
                    projectID: project,
                    startTime: ReactSession.get("StartTime")
                }
                let sessionKeys = {
                    ID: parseInt(sessionStorage.getItem("session")),
                    Online: true
                }
                console.log("SESSION KEYS: ", sessionKeys)
                let sessionResponse = await put("DesktopApp/Online", sessionKeys)
                console.log("sessionResponse: ", sessionResponse)
                let response = await post('WebTracker/ChangeTrackerStatus', keys)
                sessionStorage.setItem("jobID", response)
                console.log("Task started")
            }
            showInfo("Web tracker is now online")
        }else{
            let keys = {
                jobId: sessionStorage.getItem("jobID"),
                username: user.username
            }
            let sessionKeys = {
                ID: parseInt(sessionStorage.getItem("session")),
                Online: false
            }
            console.log("SESSION KEYS: ", sessionKeys)
            let sessionResponse = await put("DesktopApp/Online", sessionKeys)
            console.log("sessionResponse: ", sessionResponse)
            let response = await post('WebTracker/RemoveJob', keys)
            console.log(response)
            sessionStorage.removeItem("jobID")
            showInfo("Web tracker is now offline")
        }
      };
    useEffect(() => {
        getUserProjects()
        if(user && project !='default' && open){
            getWeeklyTrackedTimeUpdated(user.id, project)
        }
        
    }, [open])

    return (
        <div className="tracker-component">
            <Toast ref={toast} position="top-center"/>
        <button onClick={handleClick} className="trackerbutton">Web Tracker</button>
        <Popper id={id} open={open} anchorEl={anchorEl} transition className="tracker-popper">
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Box sx={{ border: 1, p: 1, bgcolor: 'background.paper' }} className="tracker-popper-header">
                <Typography variant="h4" component="h4"> 
                    {user.firstName} {user.lastname}
                </Typography>
                <Typography id="modal-modal-title" variant="p" component="p">
                    {user.jobDescription}
                </Typography>
                <Box id="modal-description" sx={{ mt: 2 }} className="tracker-popper-content">
                    <div className="tracker-content">
                        <div className="tracker-weekly-detail">
                            <p>This week</p>
                            <p>{weeklyTrackedTime} <MdTimer /></p>
                        </div>
                        <div className="tracker-daily-detail">
                            <p>Current Session</p>
                            <p>{dailyTracketTime} <MdTimer /></p>
                        </div>
                        <div className="tracker-start-options">
                        <select className="tracker-projects" onChange={handleProjectSelection} defaultValue={project} disabled={checked}>
                            <option value="default">-- Click to select your project --</option>
                            {projects.map((option) => (
                                <option key={option.id} value={option.id}>
                                    {option.name}
                                </option>
                            ))}
                        </select>
                        <div className="modal-controllers">
                            <FormGroup>
                                <FormControlLabel
                                    disabled={project == "default"}
                                    control={
                                                <Switch 
                                                    onClick={handleChange} 
                                                    checked={checked}
                                                />
                                            }
                                    label={checked ? "Online" : "Offline"}
                                />
                            </FormGroup>
                            <button onClick={handleClose} className="close-tracker-modal-btn">Close</button>
                        </div>
                        </div>
                    </div>
                </Box>
                {lastUpdate !='' &&(
                    <Typography variant="p" component="p" className="last-update-label">
                        Last Update: <span className="last-update-moment">{lastUpdate}</span>                                                                           
                    </Typography>
                )}
            </Box>
          </Fade>
        )}
        </Popper>
        </div>
    )
}