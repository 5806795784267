import ManagerSidebar from "../components/layout/sidebar/ManagerSidebar";
import AuditDetails from "../components/manager/dashboard/AuditDetails";
function Audit() {
  return (
    <div>
      
      <AuditDetails />
    </div>
  );
}

export default Audit;
