
import DiaryTimeCard from "./DiaryTimeCard";
import './DiaryTimeline.css'

function DiaryTimeLine(props) {

  const handleSelection = (state, id) => {
    props.handleSelection(state, id)
  }

  let allDiary = [];
  let line = [];
    if(props.data != null){
      let newList = []
      props.data.forEach(async function(value){
        let str = value.time.split(':')
        let end = str[1].split(' ')
        let details = {}
        let model = {
            time: '',
            data: []
        }

        model.time = str[0] + ':00' + end[1]

        details.workDescription=value.workDescription
        details.activitytime=value.time
        details.productivityMouse=value.mouseClicks
        details.productivityKeys=value.keystrokes
        details.screensrc=value.schreenshotPath
        details.camsrc=value.webcamShotPath
        details.id=value.id
        details.marked=value.marked
        details.overtime=value.overtime
        details.status=value.status
        let item = newList.find(x => x.time.startsWith(str[0]) && x.time.endsWith(end[1]))
        if(item != null || item != undefined){
            item.data.push(details)
        }else{
            model.data.push(details)
            newList.push(model)
        }
      });
      for (let i = 0; i < newList.length; i++) {
        line = []
        for (let j = 0; j < newList[i].data.length; j++) {
          line.push( <DiaryTimeCard
            workDescription={newList[i].data[j].workDescription}
            activitytime={newList[i].data[j].activitytime}
            productivityMouse={newList[i].data[j].productivityMouse}
            productivityKeys={newList[i].data[j].productivityKeys}
            screensrc={newList[i].data[j].screensrc}
            camsrc={newList[i].data[j].camsrc}
            id={newList[i].data[j].id}
            marked={newList[i].data[j].marked}
            overtime={newList[i].data[j].overtime}
            status={newList[i].data[j].status}
            handleSelection={handleSelection}
          />);
        }
        allDiary.push(<div className="diaryHours" id="diaryHours">{line}</div>)
      }
    }
  return (<div className="container">{allDiary}</div>);
}

export default DiaryTimeLine;
