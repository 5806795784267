import "./UserOvertime.css";
import DataTableBase from "../../ui/DataTableBase";
import { ReactSession } from 'react-client-session';
import { useState,useMemo, useRef  } from "react";
import { fetchImageFromAPI, get, post } from "../../api/apiCalls";
import { useParams } from "react-router-dom";
import moment from 'moment';
import SearchSection from "../../ui/SearchSection";
import ManagerSubHeaderLinks from "../../layout/header/ManagerSubHeaderLinks";
import DefaultProfileImage from "../../../images/default.jpg"
import UserShorProfile from "../../elements/summaryElements/UserShorProfile";
import LoadingSpinner from "../../elements/UiElements/LoadingSpinner";
import RejectReasonModal from "../dashboard/RejectReasonModal";
import { baseURL } from "../../api/env";
import { Toast } from "primereact/toast";

function UserRequest(props) {
  const toast = useRef(null)
  let rowID, requestrowID, statusrowID;
  const params = useParams();
  const [openAddModal, setOpenAddModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState();
  let [data, setData] = useState([]);
  let requestStatus = ["Requested","Approved","Rejected"];
  let user = ReactSession.get("User");
  let [euser,setEuser] = useState(0);
  let [connect,setConnect] = useState(0);
  let [loading, setLoading] = useState(true)
  
  const showInfo = (text) => {
    toast.current.show({severity:'info', summary: 'Info', detail:text, life: 3000});
  }

  function openRejectReasonModal(row) {
    setSelectedRow(row)
    setOpenAddModal(true);
  }

  function closeRejectReasonModal() {
    setOpenAddModal(false);
    getRequests();
  }
  function cancelRejectReasonModal() {
    setOpenAddModal(false);
  }

  const columns = [
    {
        name: "Date",
        selector: (row) => row.Date,
        sortable: true,
        reorder: true,
    },
    {
        name: "Type",
        selector: (row) => row.type,
        sortable: true,
        reorder: true,
    },
    {
        name: "Project",
        selector: (row) => row.Project,
        sortable: true,
        reorder: true,
    },
    {
        name: "Start Time",
        selector: (row) => row.startTime,
        sortable: true,
        reorder: true,
    },
    {
        name: "End Time",
        selector: (row) => row.endTime,
        sortable: true,
        reorder: true,
    },
    {
        name: "Hours",
        selector: (row) => row.Hours,
        sortable: true,
        reorder: true,
    },
    {
        name: "Reason",
        selector: (row) => row.reason,
        conditionalCellStyles: [
          {
            when: (row) => row.reason !== "",
            classNames: ["reason-col"],
          },
          
        ],
        sortable: true,
    },
    {
        name: "Approver",
        selector: (row) => row.Manager,
        sortable: true,
        reorder: true,
    },
    {
        name: "Reject Reason",
        selector: (row) => row.rejectReasonID,
        conditionalCellStyles: [
          {
            when: (row) => row.rejectReason !== "",
            classNames: ["reject-reason-col"],
          },
          
        ],
        sortable: true,
        reorder: true,
    },
    {
        name: "Status",
        selector: (row) => row.Status,
        button: true,
        wrap: true,
        minWidth: "200px",
        conditionalCellStyles: [
            {
            when: (row) => row.Status == "Approved",
            classNames: ["approved"],
            },
            {
            when: (row) => row.Status == "Rejected",
            classNames: ["rejected"],
            },
            {
            when: (row) => row.Status == "Requested",
            classNames: ["requested"],
            },
        ],
        cell: (row) => (
            (requestrowID = "request-buttons-" + row.id.toString()),
            (statusrowID = "request-status-" + row.id.toString()),
            (rowID = row.Status),
            (
            <div>
                <div
                className="manual-hours-buttons"
                id={requestrowID}
                style={{ display: rowID == "Requested" ? "block" : "none" }}
                >
                <button
                    className="approve-button"
                    onClick={() => ApproveRequest(row)}
                    id={row.id}
                >
                    Approve
                </button>
                <button
                    className="reject-button"
                    onClick={() => openRejectReasonModal(row)}
                    id={row.id}
                >
                    Reject
                </button>
                </div>
                <div
                id={statusrowID}
                value={row.Status}
                style={{ display: rowID == "Requested" ? "none" : "block" }}
                >
                {row.Status}
                </div>
            </div>
            )
        ),
        sortable: true,
        reorder: true,
    },
  ];


  const ApproveRequest = (row) => {
    changeStatus(row,1);
    getRequests();

  };

  let changeStatus= async(requestData,status) => {
    requestData.type === "Manual Time"? await changeManualTimeStatus(requestData, status): await changeOvertimeStatus(requestData, status)
  };
  

  const changeManualTimeStatus = async(data,status) => {
    let formatedValue = data.Hours.toString()
      let keys = {
        date : moment(data.Date).format("YYYY-MM-DD"),
        status : status,
        managerID : user.id,
        userID : params.employeeId,
        project: data.Project,
        hours: formatedValue
      };
      let response = await post("Request/ManualTime/ChangeStatusByDate",keys);
      showInfo(response)
      getRequests();
  }

  const changeOvertimeStatus = async(data,status) => {
    let keys = {
      date : moment(data.Date).format("YYYY-MM-DD"),
      status : status,
      managerID : user.id,
      userID : params.employeeId
    };
    let response = await post("Request/Overtime/ChangeStatusByDate",keys);
    showInfo(response)
    getRequests();
  }

  let rsummary= null;
  let id = 1
  const getRequests = async () => { 
      let data = [];
      rsummary = await get(`Request/GetUserRequestCollection?userID=${params.employeeId}`);
      console.log("SUMMARY: ", rsummary);
      for (const item of rsummary) {
        data.push( {
          id : id,
          Date : moment(item.date).format("YYYY-MM-DD"),
          type: item.type,
          Hours : item.hours,
          Manager : item.managerName,
          ProjectID : item.projectId,
          Project: item.projectName,
          reason : item.reason,
          Status : requestStatus[item.status],
          approvalDate: item.approvalDate == null? '' :  moment(item.approvalDate).format('YYYY-MM-DD'),
          startTime: item.startTime,
          endTime: item.endTime,
          rejectReasonID: item.rejectReasonID
        });
        id = id + 1
      }
      setData(data);
      
  }
  useMemo(() => {
    getRequests();
    // componentWillMount events
  },[]);
  
  const getUser = async () => {
   
    
    let keys = {
      id : params.employeeId
    };
    let u = await post("Users/GetUserById",keys);
    u.image = u.image === null ? DefaultProfileImage : await fetchImageFromAPI(`${baseURL}TrackingData/GetProfileImages?ImagePath=${u.image}`)
    console.log(u);
   
      
 
    setEuser(u);
    setLoading(false)
  }
   if(connect==0){
    setConnect(1);
    getUser();
  }
  return (
    <div className="summary">
      <Toast ref={toast} position="top-center"/>
      {loading && <LoadingSpinner />}
      {!loading && (
        <>
        <SearchSection />
        <div className="summaryMain">
          <div className="my-dashboard-header">
            <UserShorProfile
              employeeName={euser.firstName + ' ' + euser.lastname}
              employeePronoun={euser.pronoun}
              employeeDesignation={euser.jobDescription}
              employeeID={euser.id}
              employeeUsername={euser.username}
              employeeLocation={euser.city}
              employeePicture={euser.image}
              employeeDisabled={euser.disabled}
              employeeContractEndDate={euser.contract_endDate}
            />
            <ManagerSubHeaderLinks id={params.employeeId}/> 
          </div>
          <div className="summaryDiary">
            <div className="users-request-summaryContent">
              <div className="manual-hours ot-hours">
              
              {(() => {
                      if (data.length>0){
                          return (
                            <DataTableBase columns={columns} data={data} fileName={"Overtime_Requests"} />
                          
                          )
                      }
                      return null;
              })()}
              </div>
            </div>
          </div>
          </div>
        </>
      )}

    {openAddModal && <RejectReasonModal onCancel={closeRejectReasonModal} onClose={cancelRejectReasonModal} selectedRow={selectedRow} managerID={user.id} userID={euser.id}/>}
    </div>
  );
}

export default UserRequest;
