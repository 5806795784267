import "./EmployeeSummary.css";
import SummaryCard from "../../ui/SummaryCard";
import DateRangeSelector from "../../ui/DateRangeSelector";
import SummaryChartCard from "../../elements/summaryElements/SummaryChartCard";
import SubHeaderLinks from "../../layout/header/SubHeaderLinks";
import EmployeeShortProfile from "../../elements/summaryElements/EmployeeShortProfile";
import { ReactSession } from 'react-client-session';
import { get } from "../../api/apiCalls";
import React, { useState,useMemo  } from 'react';
import Joyride from "react-joyride"
import moment from 'moment';
import useProfileImage from "../../../hooks/useProfileImage";


function Summary() {
  const [TotalTime, setTotalTime] = useState(0);
  const [ActiveTime, setActiveTime] = useState(0);
  const [IdleTime, setIdleTime] = useState(0);
  const [ManualTime, setManualTime] = useState(0);
  const [Overtime, setOvertime] = useState(0);
  const [announcement, setAnnouncement] = useState();
  const userImage = useProfileImage();
  let [from, setFrom] = useState(0);
  let [to, setTo] = useState(0);
  const [productivityRate, setProductivityRate] = useState(0);

  const FirstAnnounce = () => {
    return(
      <div>
        <p>{announcement}</p>
      </div>
    )
  }
  const steps = [
    {
      target: 'body',
      content: <FirstAnnounce />,
      placement: "center",
      title: <h2 className="joyride-title"> Important News</h2>
    }
  ]
  let user = ReactSession.get("User");
  
  const getSummaryDetails = async () => {
    let start = moment(from).format("YYYY-MM-DD")
    let end = moment(to).format("YYYY-MM-DD")
    summary = await get(`Reports/GetUserSummary?from=${start}&to=${end}&userID=${user.id}`);
    setTotalTime(summary.totalTime);
    setActiveTime(summary.activeTime);
    setIdleTime(summary.idleTime);
    setManualTime(summary.requested);
    setOvertime(summary.overtime)
    setProductivityRate(parseFloat(summary.activeTime/summary.totalTime).toFixed(1)*100);
  
  };

  const getAnnouncement = async() => {
    let announcement = sessionStorage.getItem('announcement')
    if(announcement === null || announcement === undefined){
      let response = await get("Announcements")
      let announce = response[0].announcement
      setAnnouncement(announce)
      sessionStorage.setItem('announcement', announce)
    }else{
      setAnnouncement(announcement)
    }
  }

  let summary;// = getSummaryDetails();
  
  const setFromDate = (date) => {
    setFrom(date);
    from = date;
    getSummaryDetails();
  };

  const setToDate = (date) => {
    setTo(date);
    to=date;
    getSummaryDetails();
  };

  useMemo(() => {
    let date = moment();
    setFrom(date);
    setTo(date);
    from = date;
    to = date;
    getSummaryDetails();
    getAnnouncement();
  },[]);
  
  return (
    <div className="summary">
      {!user.isFirstLogin &&(
        <Joyride
          steps={steps}
          styles={{
            options: {
              zIndex: 9999,
            }
          }}
        />
      )}
      <div className="employee-summary-main">
        <div className="my-dashboard-header">
          <SubHeaderLinks />
        </div>
        <div className="employee-summary-box">
          <div className="" id="step-1">
            <div className="employee-summary-info">
              <div className="employee-summary-cards-box">
                <div className="selectors">
                  <DateRangeSelector
                    onFromChangeDate = {setFromDate}
                    onToChangeDate = {setToDate}  
                    
                  />
                  <p className="timezone-alert">All time in the summary is on the UTC timezone</p>
                </div>
                <div className="employee-summary-cards">
                  <SummaryCard title="Tracked Time" cardtime={TotalTime} info='Your total tracked time via the wisdom tracker.'/>
                  <SummaryCard title="Idle Time" cardtime={IdleTime} info=' Your total Idle time, idle timecards are the ones without activity.'/>
                  <SummaryCard title="Manual Time" cardtime={ManualTime} info='Manual Time is a way to add time to your diary without having to track. This must be approved by your manager.'/>
                  <SummaryCard title="Payroll Time" cardtime={ActiveTime} info='Tracked Time + approved Manual Time.'/>
                  <SummaryCard title="Approved OT" cardtime={Overtime} info='The amount of approved Overtime in the wisdom dashboard. This will allow you to track over the project cap, the time still must be tracked via the tracker.'/>
                </div>
              </div>
              <div className="employee-summary-chard-card">
                <SummaryChartCard
                  chartTitle="Productivity Score"
                  Productive={ActiveTime}
                  Idle={IdleTime}
                  Manual={ManualTime}
                  Total={TotalTime}
                  productivityRate={TotalTime}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Summary;
