import { NavLink } from "react-router-dom";

import "./SubHeaderLinks.css";

function SubHeaderLinks(props) {
  return (
    <div className="manager-subheader-links">
      <ul>
        <li>
          <NavLink className={({ isActive }) => isActive ? "active" : "" } to={"/manager/summary/"+props.id}>
            Summary
          </NavLink>
        </li>
        <li>
          <NavLink className={({ isActive }) => isActive ? "active" : "" } to={"/manager/personalInfo/" + props.id}>
            Personal Info
          </NavLink>
        </li>
        <li>
          <NavLink className={({ isActive }) => isActive ? "active" : "" } to={"/manager/diary/"+props.id}>
            Diary
          </NavLink>
        </li>
        <li>
          <NavLink className={({ isActive }) => isActive ? "active" : "" } to={"/manager/user-requests/"+props.id}>
            Requests
          </NavLink>
        </li>
        <li>
          <NavLink className={({ isActive }) => isActive ? "active" : "" } to={"/manager/compliance/"+props.id}>
            Compliance
          </NavLink>
        </li>
      </ul>
      
    </div>
  );
}

export default SubHeaderLinks;
