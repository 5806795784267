import "./Requests.css";
import DataTableBase from "../../ui/DataTableBase";
import { useState,useMemo, useRef  } from "react";
import ManualFormModal from "../../elements/profileElements/ManualFormModal";
import { post, get, remove } from "../../api/apiCalls";
import { ReactSession } from 'react-client-session';
import moment from "moment";
import Joyride from "react-joyride"
import { useNavigate } from "react-router-dom";
import { GrDocumentTime } from "react-icons/gr";
import OvertimeFormModal from "../../elements/profileElements/OvertimeFormModal";
import LoadingSpinner from '../../elements/UiElements/LoadingSpinner'
import ProfileSubHeaderLinks from "../../layout/header/ProfileSubHeaderLinks";
import { Toast } from "primereact/toast";

function Requests(props) {
  const toast = useRef(null)
  const [isFirstLogin, setIsFirstLogin] = useState('true')
  let rowID, requestrowID, statusrowID;
  let [data, setData] = useState([]);
  let [loading, setLoading] = useState(false)
  let user = ReactSession.get("User");
  let requestStatus = ["pending","accepted","refused"];
  const history = useNavigate();
  const [{ run, steps }, setState] = useState({
    run:true,
    steps: [
      {
        content: "Here you will be able to request manual time or overtime from your manager.",
        title: <h2><GrDocumentTime /> Request Page</h2>,
        locale: {skip: <strong>SKIP</strong>},
        placement: "center",
        target: 'body'
      },
      {
        content: 'This is where you can find your requested Manual time and Overtime and their status.',
        placement: "bottom",
        target: "#step-0",
        title: <h2>Requests</h2>
      },
      {
        content: 'Here you will find Manual Time request form.',
        placement: "bottom",
        target: "#step-1",
        title: <h2>Manual Time Request</h2>
      },
      {
        content: 'And here you will find Overtime request form.',
        placement: "bottom",
        target: "#step-20",
        title: <h2>Overtime Request</h2>
      },
      
    ]
  })
  
  const handleTourEvent = (event) => {
    console.log("EVENT: ", event)
    if(event.index == 3 && event.status == 'finished' || event.action == 'skip'){
      history("/diary")
    }
  }

  const showInfo = (text) => {
    toast.current.show({severity:'info', summary: 'Info', detail:text, life: 3000});
  }

  const columns = [
    {
      name: "Date",
      selector: (row) => row.Date,
      sortable: true,
      reorder: true,
    },
    {
      name: "Type",
      selector: (row) => row.type,
      sortable: true,
      reorder: true,
    },
    {
      name: "Project",
      selector: (row) => row.Project,
      sortable: true,
      reorder: true,
    },
    {
      name: "Start Time",
      selector: (row) => row.startTime,
      sortable: true,
      reorder: true,
    },
    {
      name: "End Time",
      selector: (row) => row.endTime,
      sortable: true,
      reorder: true,
    },
    {
      name: "Hours",
      selector: (row) => row.Hours,
      sortable: true,
      reorder: true,
    },
    {
      name: "Reason",
      selector: (row) => row.reason,
      conditionalCellStyles: [
        {
          when: (row) => row.reason !== "",
          classNames: ["reason-col"],
        },
        
      ],
      sortable: true,
      reorder: true,
    },
    {
      name: "Approver",
      selector: (row) => row.Manager,
      sortable: true,
      reorder: true,
    },
    {
      name: "Approval Date",
      selector: (row) => row.approvalDate,
      sortable: true,
      reorder: true,
    },
    {
      name: "Reject Reason",
      selector: (row) => row.rejectReason,
      conditionalCellStyles: [
        {
          when: (row) => row.rejectReason !== "",
          classNames: ["reject-reason-col"],
        },
        
      ],
      sortable: true,
      reorder: true,
    },
    {
      name: "Status",
      selector: (row) => row.Status,
      conditionalCellStyles: [
        {
          when: (row) => row.Status == "accepted",
          classNames: ["approved"],
        },
        {
          when: (row) => row.Status == "refused",
          classNames: ["rejected"],
        },
        {
          when: (row) => row.Status == "pending",
          classNames: ["requested"],
        },
      ],
      
      cell: (row) => (
        (requestrowID = "request-buttons-" + row.id),
        (statusrowID = "request-status-" + row.id),
        (rowID = row.Status),
        (
          <div>
            <div
              className="manual-hours-buttons"
              id={requestrowID}
              style={{ display: rowID == "pending" ? "block" : "none" }}
            >
              <span>
                {rowID} <button
                    className="cancel-button"
                    onClick={() => cancelRequest(row)}
                    id={row.id}
                    >
                    cancel
                  </button>
              </span>
            </div>
            <div
              id={statusrowID}
              value={row.Status}
              style={{ display: rowID == "pending" ? "none" : "block" }}
            >
              {row.Status}
            </div>
            <div
              id={statusrowID}
              value={row.Status}
              style={{ display: rowID == "refused" ? "block" : "none" }}
            >
              <span>
                <button className="delete-button" onClick={() => deleteRequest(row)} id={row.id}>
                  delete
                </button>
              </span>
            </div>
          </div>
        )
      ),
      sortable: true,
      reorder: true,
    },
  ];

  const cancelRequest = (row) => {
    CancelRequest(row);
    getRequests();
  }

  const deleteRequest = (row) => {
    console.log("DELETED ROW: ", row)
    DeleteRefusedRequest(row);
    getRequests();
  }

  let DeleteRefusedRequest = async(data) => {
    setLoading(true)
    let keys = {
      userID : user.id,
      date : moment(data.Date).format("YYYY-MM-DD"),
      projectId: data.ProjectID
    };
    console.log("KEYS: ", keys)
    if(data.type === 'Overtime'){
      await remove("Request/Overtime/DeleteOvertimeRequest", keys)
      showInfo("Request was deleted")
    }else{
      await remove("Request/ManualTime/DeleteManualTimeRequest",keys);
      showInfo("Request was deleted")


    }
   getRequests();
  }

  let CancelRequest= async(requestData) => {
    setLoading(true)
    let keys = {
      userID : user.id,
      date : moment(requestData.Date).format("YYYY-MM-DD"),
      projectId: requestData.ProjectID
    };
    if(requestData.type === 'Overtime'){
      await remove("Request/Overtime/CancelOvertimeRequest", keys)
      showInfo('Request was canceled')
    }else{
      await remove("Request/ManualTime/CancelManualTimeRequest",keys);
      showInfo('Request was canceled')
    }
   getRequests();
  };
  

  const [FormModalIsOpen, setFormModalIsOpen] = useState(false);
  const [FormOtModalIsOpen, setFormOtModalIsOpen] = useState(false);

  function openForm() {
    setFormModalIsOpen(true);
  }

  function closeModalHandler() {
    setFormModalIsOpen(false);
  }
  function openOtForm() {
    setFormOtModalIsOpen(true);
  }

  function closeOtModalHandler() {
    setFormOtModalIsOpen(false);
  }

  let assignDataToTable= (rsummary) => {
    let list = [];
    for (const item of rsummary) {
      list.push( {
        Date : moment(item.date).format("YYYY-MM-DD"),
        type: item.type,
        Hours : item.hours,
        Manager : item.managerName,
        ProjectID : item.projectId,
        Project: item.projectName,
        reason : item.reason,
        Status : requestStatus[item.status],
        approvalDate: item.approvalDate == null? '' :  moment(item.approvalDate).format('YYYY-MM-DD'),
        startTime: item.startTime,
        endTime: item.endTime,
        rejectReason: item.rejectReasonID
      });
      
    }
    setData(list);
    setLoading(false)
  }

  const getRequests = async () => {
    let manualTimes = [];
    manualTimes = await get(`Request/GetUserRequestCollection?userID=${user.id}`);
    assignDataToTable(manualTimes);
    setLoading(false)
  }

  

  function requestTime(mto) {
    setFormModalIsOpen(false);
    let request = async () => {
      setLoading(true)
      
      let timelength = mto.to - mto.from;
      
      let cardsLength = timelength/(1000*60*10);
      
      let d1 = new Date(mto.from);
      
      let dates = [];
      for(let i = 0 ; i < cardsLength ; i++) {
        
        dates[i] = new Date(d1).toUTCString();
        d1.setMinutes(d1.getMinutes()+10);
      }
            
      let keys = {
        reason : mto.reason,
        userID : user.id,
        project : mto.project,
        from : mto.from,
        to : mto.to
      };
      let summary = await post("Request/ManualTime/AddRequestByTimeInterval",keys);
      mto = 0;
      showInfo(summary)
      getRequests();
      setLoading(false)
    }
    request();
  }

  function requestOverTime(mto) {
    setFormOtModalIsOpen(false);
    let request = async () => {
      setLoading(true)
      let timelength = mto.to - mto.from;
      let cardsLength = timelength/(1000*60*10);
      let d1 = new Date(mto.from);
      let dates = [];

      for(let i = 0 ; i < cardsLength ; i++) {
        dates[i] = new Date(d1).toUTCString();
        d1.setMinutes(d1.getMinutes()+10);
      }

      let keys = {
        reason : mto.reason,
        userID : user.id,
        project : mto.project,
        from : mto.from,
        to : mto.to
      };
      let summary = await post("Request/Overtime/AddRequestByTimeInterval",keys);
        showInfo(summary)
        mto = 0;
        getRequests();
        setLoading(false)
    }
    request();
  }
   
  useMemo(() => {
    getRequests();
    setIsFirstLogin(user.isFirstLogin)
  },[]);

  return (
    <>
      <Toast ref={toast} position="top-center"/>
      <div className="profileMain">
        <ProfileSubHeaderLinks />
        <div className="manual-hours" id="step-0">
          <div className="personal-info-header">Requests</div>
          {isFirstLogin && (
            <Joyride
              continuous
              showSkipButton
              run={run}
              steps={steps}
              showProgress
              disableScrolling
              callback={handleTourEvent}
              styles={{
                options: {
                  zIndex: 9999,
                }
              }}
            />
          )}
          <div>
          {!user.disabled &&(
            <div className="requests-forms-btns">
              <button id="step-1" className="request-mt-button" onClick={openForm} disabled={loading}>
                Request Manual Time
              </button>
              <button id="step-20" className="request-ot-button" onClick={openOtForm} disabled={loading}>
                Request Overtime
              </button>
            </div>
          )}
          </div>
          {loading === true
            ? <LoadingSpinner />
            : <DataTableBase columns={columns} data={data}  fileName={"Manual_Time_Requests"} showDownloadButton={false}/>
          }
        </div>
        {FormModalIsOpen && <ManualFormModal onCancel={closeModalHandler} setMto={requestTime} />}
        {FormOtModalIsOpen && <OvertimeFormModal onCancel={closeOtModalHandler} setMto={requestOverTime} />}
      </div>
    </>
  );
}

export default Requests;
