import React from 'react';
import { ReactSession } from 'react-client-session';
import axios from 'axios';
import { baseURL } from './env';
import DefaultImage from "../../images/default.jpg"
let cors = require('cors');
const token = sessionStorage.getItem("token")
const qs = require('qs');
import { Toast } from 'primereact/toast';

let corsConfig = {
  headers : {
    'Application' : 'React',
    'Content-Type' : 'application/x-www-form-urlencoded',
    'Authorization': `Bearer ${token}`
    }
}

const queryCorsConfig = {
  headers: {
    'Application': 'React',
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`
  }
};

ReactSession.setStoreType("localStorage");

const setMetaData = async(keys,ipRequired = false) => {
    if(keys == null)
      keys = {};
    if(ipRequired) {
      const res = await axios.get('https://geolocation-db.com/json/');
      keys.ip = res.data.IPv4;
    }
    keys.Application = "React"
}

let CancelToken = axios.CancelToken;
let source = null;

export const post = async (url,keys,ipRequired = false) => {
  if(source == null) {
    source = CancelToken.source();
    corsConfig.headers.Application = 'React'

  } else {
    source.cancel('Operation canceled by the user.');
    source = CancelToken.source();
    corsConfig.cancelToken = source.token;
  }
  
  await setMetaData(keys,ipRequired);
  let requestResponse;
  await axios.post(baseURL + url,qs.stringify(keys), {headers: corsConfig.headers}).then(function (response) {
    requestResponse = response.data;
  })
  .catch(function (error) {
    // handle error
    if(error.toString().endsWith('401')){
      alert("Your token has expired, please log in again")
      sessionStorage.clear();
      window.location.href = "/signin"
    }
  })
  .then(function () {
    // always executed
    source = null;
  });
  return requestResponse;
}

export const put = async (url,keys,ipRequired = false) => {
  if(source == null) {
    source = CancelToken.source();
    corsConfig.headers.Application = 'React'

  } else {
    source.cancel('Operation canceled by the user.');
    source = CancelToken.source();
    corsConfig.cancelToken = source.token;
  }
  
  await setMetaData(keys,ipRequired);
  let requestResponse;
  await axios.put(baseURL + url,qs.stringify(keys), {headers: corsConfig.headers}).then(function (response) {
    
    requestResponse = response.data;
  })
  .catch(function (error) {
    if(error.toString().endsWith('401')){
      alert("Your token has expired, please log in again")
      sessionStorage.clear();
      window.location.href = "/signin"
    }
  })
  .then(function () {
    // always executed
    source = null;
  });
  return requestResponse;
}

export const get = async (url,keys) => {
  await setMetaData(keys);
  
  let requestResponse;
  
  await axios.get(baseURL + url,{ params : keys, headers: corsConfig.headers}).then(function (response) {
    
     requestResponse = response.data;
  })
   .catch(function (error) {
    if(error.toString().endsWith('401')){
      alert("Your token has expired, please log in again")
      sessionStorage.clear();
      window.location.href = "/signin"
    }
   })
   .then(function () {
     // always executed
   });
 return requestResponse;
}

export const newPost = async(url, keys) => {
  await setMetaData(keys);
  await axios.post(baseURL + url, qs.stringify(keys), {headers: corsConfig.headers})
    .then(response => {
      alert(response.data)
    })
    .catch(function(error) {
      if(error.toString().endsWith('401')){
        alert("Your token has expired, please log in again")
        sessionStorage.clear();
        window.location.href = "/signin"
      }
      if (error.response){
        alert(error.response.data)
      }
    })
}

const toQueryString = (params) => {
  return Object.keys(params)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(params[key]))
    .join('&');
};

export const remove = async (url, keys) => {
  const queryParams = toQueryString(keys);
  const fullUrl = `${baseURL}${url}?${queryParams}`;

  await axios.delete(fullUrl, {
    headers: queryCorsConfig.headers,
  })
    .then(response => {
      return(response.data);
    })
    .catch(error => {
      return(error.response.data);
    });
};

export const fetchImageFromAPI = async(url) => {
  try {
    const response = await fetch(url, {headers: corsConfig.headers});
    if (!response.ok) {
      throw new Error('Error fetching the image');
    }
    const blob = await response.blob();
    return URL.createObjectURL(blob);
  } catch (error) {
    console.error('Error fetching the image:', error);
    throw error;
  }
}

export const loginCall = async(url, keys) => {
  try {
    const response = await axios.post(baseURL + url, qs.stringify(keys), {
      headers: corsConfig.headers,
    });

    if (response.status !== 200) {
      throw new Error(`Error ${response.status}, please check your data`);
    }

    const user = response.data.userSession;
    const token = response.data.token;
    const image = response.data.userImage;

    if (image !== null && image !== undefined) {
      sessionStorage.setItem("profileImage", image); // Salva a imagem Base64 no localStorage
    } else {
      sessionStorage.setItem("profileImage", DefaultImage);
    }

    ReactSession.set("User", user);
    sessionStorage.setItem("token", token);

    return user;
  } catch (error) {
    console.error("Something went wrong: ", error);
  }
};

export const SignIn_URL = 'Users/Login';
