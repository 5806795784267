import React, { useMemo } from 'react';
import { ReactSession } from 'react-client-session';
import { post, put } from "./components/api/apiCalls"; 
import { PrimeReactProvider } from "primereact/api"
import "primereact/resources/themes/lara-light-cyan/theme.css";
import useUserRole from './hooks/useUserRole'
import { Router } from "./Router";


function App() {
  const userRole = useUserRole()
  console.log("USER ROLE: ", userRole)
  let tracker = {
    online:false,
    projectId:0,
    project:'default',
    weeklyTime: 0,
    dailyTime: 0
  }
  ReactSession.set("Tracker", tracker)
  useMemo(() => {
    window.addEventListener('beforeunload', async function(event) {
      let tracker = ReactSession.get("Tracker")
      let loggedUser = ReactSession.get("User")
      if(tracker.online){
        event.preventDefault();
        
        let key = {
            jobId: sessionStorage.getItem("jobID"),
            username: loggedUser.username
        }
        let keys = {
          ID: parseInt(sessionStorage.getItem('session')),
          Online: false
        }
        await put('DesktopApp/Online', keys)
        let response = await post("WebTracker/RemoveJob", key)
        if(response){
            tracker.online = false
            ReactSession.set("Tracker", tracker)
            alert("Your web tracker was set to offline, please turn it on again")
            window.location.reload()
        }

        return 'Are you sure you want to leave?';
      }
    });

  },[]);
  
  return (
    <PrimeReactProvider>
      <Router/>
    </PrimeReactProvider>
  );
  
}

export default App;
