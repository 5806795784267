import { NavLink } from "react-router-dom";
import EmployeeShortProfile from "../../elements/summaryElements/EmployeeShortProfile";
import { ReactSession } from 'react-client-session'
import useProfileImage from "../../../hooks/useProfileImage";
import "./SubHeaderLinks.css";

function SubHeaderLinks() {
  const userImage = useProfileImage();
  const user = ReactSession.get('User')

  return (
    <div className="links">
      <div>
        <EmployeeShortProfile
          employeeName={user.firstName + ' ' + user.lastname}
          employeePronoun={user.pronoun}
          employeeDesignation={user.jobDescription}
          employeeID={user.id}
          employeeUsername={user.username}
          employeeLocation={user.city}
          employeePicture={userImage}
        />
      </div>
      <div className="subheader-links">
        <ul>
          <li>
            <NavLink className={({ isActive }) => isActive ? "active" : "" } to="/summary">
              Summary
            </NavLink>
          </li>
          <li>
            <NavLink className={({ isActive }) => isActive ? "active" : "" } to="/diary" id='step-5'>
              Diary
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default SubHeaderLinks;
