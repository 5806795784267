
import ManagerSidebar from "../components/layout/sidebar/ManagerSidebar";
import UserRequest from "../components/manager/employeeprofile/UserRequest";


function UsersRequests() {
  return (
    <div>
      
      <UserRequest />
    </div>
  );
}

export default UsersRequests;
