
import ManagerSidebar from "../components/layout/sidebar/ManagerSidebar";
import UserDailyDiary from "../components/manager/dashboard/UserDailyDiary";

function UserDiary() {
  return (
    <div>
      
      <UserDailyDiary />
    </div>
  );
}

export default UserDiary;
