
import DashboardMain from "../components/layout/main/DashboardMain";
import ManagerSidebar from "../components/layout/sidebar/ManagerSidebar";
function ManagerDashboard() {
  return (
    <div>
      
      <DashboardMain />
    </div>
  );
}

export default ManagerDashboard;
