import EmployeeSummary from "../components/employee/dashboard/EmployeeSummary";
import ManagerSidebar from "../components/layout/sidebar/ManagerSidebar";
import Joyride from "react-joyride"
import { useNavigate } from "react-router-dom";
import React, { useState,useMemo  } from 'react';
import { ReactSession } from 'react-client-session';
import { ImInsertTemplate } from "react-icons/im";
import { BiDetail } from "react-icons/bi";
import { FaUser } from "react-icons/fa";
import { BsLink45Deg } from "react-icons/bs";
import { GrDocumentTime } from "react-icons/gr"; 

function EmployeeSummaryDashboard() {
  const history = useNavigate();
  const [isFirstLogin, setIsFirstLogin] = useState('true')
  const [{ run, steps }, setState] = useState({
    run:true,
    steps: [
      {
        content: <p>Welcome to the wisdom onboarding! <br/>Click on <strong>Next</strong> to start your journey with us.</p>,
        title: <h2>Wisdom Onboarding</h2>,
        locale: {skip: <strong>SKIP</strong>},
        placement: "center",
        target: 'body'
      },
      {
        content:
        <>
        <p><strong><ImInsertTemplate /> My Dashboard</strong> - Here you can find a summary of all your details.</p>
        <p><strong><BiDetail /> My Daily Diary</strong> - Your time cards can be found here.</p>
        <p><strong><FaUser /> My Info</strong> - Want to edit your basic information? You do that here.</p>
        <p><strong><BsLink45Deg /> Links</strong> - You can find all of our usefull links here.</p>
        <p><strong><GrDocumentTime /> My Requests</strong> - Here you can find info on any manual time and overtime you might have logged.</p>
        </>,
        placement: "right",
        target: "#step-sidebar",
        title: <h2>Left Side Menu</h2>
      },
      {
        content:
        <p>Here you will find your daily tracked, idle, manual and productive times.
          <br/>
          <br/>
          a. Productive Time is your Idle Time subtracted from your Tracked Time.
          <br/>
          <br/>
          b. Your actual time, that will be used in your payments, will be the total of your Productive and Manual times.
          <br/>
          <br/>
          c. You can filter based on date ranges by clicking on the date fields to view your history.
        </p>,
        placement: "bottom",
        target: "#step-1",
        title: <h2><ImInsertTemplate /> Profile Summary</h2>
      },
      {
        content: 
        <p>
          If your running Windows or Mac, we've got you covered! Click here to download the Wisdom Tracker App. 
          <br/>
          <br/>
          <strong>Please use it to track your time according to your schedule.</strong>
        </p>,
        placement: "left",
        target: '#step-2',
        spotlightClicks: true,
        title: <h2>Tracker</h2>,
        
      },
    ]
  })


  const handleTourEvent = (event) => {
    if(event.index == 3 && event.status == 'running'){
      event.step.styles.spotlight = {...event.step.styles.spotlight, minHeight: '130px'}
    }
    if(event.type == "tour:end"){
      history("/profile/info")
    }
  }



  useMemo(async() => {
    const user = ReactSession.get("User");
    setIsFirstLogin(user.isFirstLogin)
    
  },[]);

  return (
    <div>
      {isFirstLogin && (
        <Joyride
          continuous
          showSkipButton
          run={run}
          steps={steps}
          showProgress
          disableScrolling
          callback={handleTourEvent}
          styles={{
            options: {
              zIndex: 9999,
            }
          }}
        />

      )}
      
      <EmployeeSummary/>
    </div>
  );
}

export default EmployeeSummaryDashboard;
