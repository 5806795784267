
import ManagerSidebar from "../components/layout/sidebar/ManagerSidebar";
import UserCompliances from "../components/manager/employeeprofile/UserCompliances";

function UserCompliance(){
    
    return(
        <div>
            
            <UserCompliances/>
        </div>
    )
}


export default UserCompliance;