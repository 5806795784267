
import ManagerSidebar from "../components/layout/sidebar/ManagerSidebar";
import DashboardManagerPayrollFullDetails from "../components/manager/teamDashboards/DashboardManagerPayrollFullDetails";

function ManagerPayrollFullDetails() {
  return (
    <>
      <DashboardManagerPayrollFullDetails />
    </>
  );
}

export default ManagerPayrollFullDetails;
